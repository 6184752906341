@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@font-face {
  font-family: "Roboto-bold";
  src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap")
    format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "LOICondensed_2023-Regular";
  src: url("./LOICondensed_2023-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dubai-Medium";
  src: url("./Dubai_Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald-SemiBold";
  src: url("./Oswald-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TitilliumWeb-Regular";
  src: url("./TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TitilliumWeb-Bold";
  src: url("./TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Tomorrow-Regular";
  src: url("./Tomorrow-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Tomorrow-SemiBold";
  src: url("./Tomorrow-SemiBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Tomorrow-ExtraBold";
  src: url("./Tomorrow-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Wallpoet";
  src: url("./Wallpoet-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Airstrike";
  src: url("./airstrike.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "DisketMono-Bold";
  src: url("./Disket-Mono-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Disket Mono";
  src: url("./Disket-Mono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPT-DemiObl";
  src: url("./FuturaPTDemi.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPT-ExtraBold";
  src: url("./FuturaPTExtraBold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaPT-LightObl";
  src: url("./FuturaPTLightOblique.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* .textBoxScores {
    width: 83 !important;
    height: 46 i !important;
    position: absolute;
    font-family: 'Exo 2', sans-serif;
    font-size: 30px;
    color: "#fff";
    text-align: center !important;
    background-color: "pink" !important;
} */
