.borderGrey {
  border: 2px solid grey;
  margin-bottom: 5px !important;
}

.backg {
  opacity: 0.5;
  filter: alpha(opacity = 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 2;
  background: rgb(255, 255, 255, 0.5);
}

.btn_p_blue {
  border: 0;
  border-color: #fbfbfd !important;
}

.btn-google {
  color: #545454;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #ddd;
}

.no_radio_button > input[type="radio"] {
  display: none;
  /* appearance: none; */
}

.fp-loader {
  top: 25%;
  left: 47%;
  z-index: 1000;
  position: absolute;
  width: 100;
  height: 100;
}

.titles-home-page {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #243e63;
  font-size: 2rem;
}

.section-size-home {
  min-height: -webkit-calc(100vh - 64px);
  height: auto;
  padding-top: 10vh;
}

.section-rest {
  min-height: -webkit-calc(100vh - 64px);
  height: -moz-calc(100vh- 64px);
  height: calc(100vh - 64px);
  height: auto;
  margin-top: -15px;
  padding-top: 0 !important;
  background-image: url("./img/background.png");
  background-size: cover;
  background-position: center;
  position: relative;
}

.row-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #104b68;
  opacity: 0.5;
}

.text-glow {
  text-align: center;
  color: #fbe5b6; /* Text color */
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

.container-instructions-db {
  width: 50vw !important;
  border: 1px solid #243e63;
  border-radius: 10px;
}

.mt-500 {
  margin-top: 250px;
}

.icons-dashbaord {
  padding: 0 !important;
  float: left !important;
  text-align: center !important;
  background-color: #fbfbfd !important;
}

#scoreboard_remote_control_box {
  height: "15vh";
  line-height: "15vh";
  background-color: black;
  margin-top: 8vh;
  text-align: center;
  vertical-align: center;
}

.scoreboard_remote_time {
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  height: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

#scoreboard_remote_control_names {
  width: 70%;
  margin: 0 auto;
  height: 35px;
  background-color: rgb(24, 92, 156);
}

.h-70 {
  height: 70px !important;
}

.w-135 {
  width: 135px !important;
}

.scoreboard_name_ind {
  height: 35px;
  text-align: center;
  vertical-align: center;
  line-height: 35px;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  color: white;
  float: left;
}

@media only screen and (max-width: 765px) and (min-width: 0px) {
  /* CSS that should be displayed if width is equal to or less than 991px and larger 
   than 768px goes here */
  .section-size-home {
    min-height: 100vh;
    padding-top: 2vh;
    height: auto;
  }

  .sections-rest {
    min-height: 100vh;
    padding-top: 0rem !important;
    padding-bottom: 2rem !important;
    height: auto;
  }

  .container-instructions-db {
    width: 90vw !important;
  }

  .fp-loader {
    left: 40%;
  }
}

.max-width-500 {
  max-width: 550px !important;
  text-align: center !important;
  margin-left: auto;
  margin-right: auto;
}

.mw-900 {
  max-width: 900px !important;
  margin-left: auto;
  margin-right: auto;
  height: 500 !important;
}

.mx-600 {
  max-width: 600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-800 {
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.inst_header {
  background-color: #fbfbfd !important;
  border: 0;
}

.height-15 {
  height: 25px !important;
}

@media screen and (min-width: 601px) {
  #scoreboardRemote {
    font-size: 120px;
    color: #fbfbfd;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  #scoreboardRemote {
    font-size: 80px;
    color: #fbfbfd;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0;
  }
}

.no-link-style {
  text-decoration: none !important;
  color: inherit !important;
}

.neg-margin {
  margin-top: -70px;
}
