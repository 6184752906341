body {
  user-select: none !important;
  margin: 0 !important;
}

.container-share {
  box-sizing: border-box;
  resize: both;
  overflow: auto;
  max-width: 100%;
  height: 100vh;
}

.presentation-place {
  user-select: none;
  background: transparent;
  display: inline-block;
  margin: 0;
  position: absolute;
  aspect-ratio: 16 / 9;
}

.slide {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background: red; */
}

@media screen and (max-aspect-ratio: 16 / 9) {
  .presentation-place {
    width: 100vw;
  }
}

@media screen and (min-aspect-ratio: 16 / 9) {
  .presentation-place {
    height: 100vh;
  }
}
