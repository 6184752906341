// /* finally, import Bootstrap */
// @import "bootstrap";

// $primary: #0CAF57;
// $danger: #2C6E63;

// @import "bootstrap";

// change the theme
$theme-colors: (
  "primary": #0CAF57,
  "secondary": #2C6E63,
  "info": #104b68
);

.secondary {
    fill: #2C6E63 !important;
    color: #2C6E63 !important;
}

.text-secondary {
    color: "#2C6E63" !important
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';