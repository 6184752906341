/*!
 * Bootstrap v5.0.0-alpha1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.fw-bold,
/* .fw-medium {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
} */

body {
  margin: 0;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d5769;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.nav-link {
  color: #fff !important;
  opacity: 0.9;
  will-change: opacity, color;
  transition: opacity 0.2s ease, color 0.2s ease;
  font-size: 18px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-decoration: none;

  position: relative;
  display: inline-block;
}

.nav-link:hover {
  color: rgba(255, 255, 255, 1);
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.75); /* Change color as needed */
  bottom: -2px;
  left: 0;
  transition: width 0.3s ease; /* Add left transition */
}

/* Animation on hover */
.nav-link:hover::after {
  width: 75%; /* Set width to 75% */
  left: 12.5%;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.text-code-box {
  font-size: 32px !important;
}

@media screen and (max-width: 542px) {
  /* Styles for screens less than 542px */
  .text-code-box {
    font-size: 16px !important;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  /* font-family: Inter, Rubik, Poppins, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-weight: 800;
  line-height: 1.2;
  color: #243e63;
}

.h1,
h1 {
  font-size: calc(1.37383rem + 1.48598vw);
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.48832rem;
  }
}

.h2,
h2 {
  font-size: calc(1.33236rem + 0.98832vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2.0736rem;
  }
}

.h3,
h3 {
  font-size: calc(1.2978rem + 0.5736vw);
}

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.728rem;
  }
}

.h4,
h4 {
  /* font-family: sans-serif !important; */
  font-size: calc(1.269rem + 0.228vw);
  font-weight: 600;
}

.fl-l {
  float: left !important;
}

.fl-r {
  float: right !important;
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.44rem;
  }
}

.h5,
h5 {
  font-size: 1.2rem;
}

.h6,
h6 {
  font-size: 0.83333rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  padding-left: 2rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul ul {
  margin-bottom: 0;
}

b {
  font-weight: 800;
}

a {
  color: #0caf57;
  text-decoration: none;
}

a:hover {
  color: #2c6e63;
  text-decoration: underline;
}

#dashboard-icons > a {
  color: #243e63;
}

#dashboard-icons > a:hover {
  color: #0d1f39;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
}

label {
  display: inline-block;
}

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

button,
textarea {
  margin: 0;
  /* font-family: inherit; */
  font-size: inherit;
  line-height: inherit;
}

[role="button"] {
  cursor: pointer;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.display-1 {
  font-size: calc(1.55498rem + 3.65978vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.29982rem;
  }
}

.display-2 {
  font-size: calc(1.48332rem + 2.79982vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.58318rem;
  }
}

.display-3 {
  font-size: calc(1.4236rem + 2.08318vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.98598rem;
  }
}

.display-4 {
  font-size: calc(1.37383rem + 1.48598vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.48832rem;
  }
}

.display-5 {
  font-size: calc(1.33236rem + 0.98832vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 2.0736rem;
  }
}

.display-6 {
  font-size: calc(1.2978rem + 0.5736vw);
  font-weight: 900;
  line-height: 1;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.728rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1540px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1480px;
  }
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  /* z-index: -1; */
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 1540px) {
  .col-xxl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.1875rem + 1px);
  padding-bottom: calc(0.1875rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 75%;
  color: #6f7995;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.3125rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4d5769;
  background-clip: padding-box;
  border: 1px solid #7a839e;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #4d5769;
  background-color: #fff;
  border-color: #9cbdfa;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.25rem rgba(56, 123, 245, 0.25);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 0 0.25rem rgba(56, 123, 245, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #4d5469;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #4d5469;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #4d5469;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #4d5469;
  opacity: 1;
}

.form-control::placeholder {
  color: #4d5469;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f8f8;
  opacity: 1;
}

textarea.form-control {
  min-height: calc(1.5em + 0.625rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.375rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 2rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.3125rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.625rem;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.625rem - 2px);
  border-top-right-radius: calc(0.625rem - 2px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.625rem - 2px);
  border-bottom-left-radius: calc(0.625rem - 2px);
}

.card > .card-header + .list-group {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.625rem - 2px) calc(0.625rem - 2px) 0 0;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.625rem - 2px);
  border-top-right-radius: calc(0.625rem - 2px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.625rem - 2px);
  border-bottom-left-radius: calc(0.625rem - 2px);
}

.card-group > .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.page-link {
  position: relative;
  display: block;
  color: #387bf5;
  background-color: #fff;
  border: 1px solid #bec3d0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #2d62c4;
  text-decoration: none;
  background-color: #f8f8f8;
  border-color: #bec3d0;
}

.page-link:focus {
  z-index: 3;
  color: #2d62c4;
  background-color: #f8f8f8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(56, 123, 245, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(56, 123, 245, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.disabled .page-link {
  color: #4d5469;
  pointer-events: none;
  background-color: #fff;
  border-color: #bec3d0;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-link {
  font-weight: 700;
}

.alert-primary {
  color: #224a93;
  background-color: #d7e5fd;
  border-color: #c3d7fc;
}

.alert-primary .alert-link {
  color: #1b3b76;
}

.alert-danger {
  color: #932222;
  background-color: #fdd7d7;
  border-color: #fcc3c3;
}

.alert-danger .alert-link {
  color: #761b1b;
}

.alert-light {
  color: #959698;
  background-color: #fefeff;
  border-color: #fdfefe;
}

.alert-light .alert-link {
  color: #77787a;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #4d5469;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item-primary {
  color: #224a93;
  background-color: #d7e5fd;
}

.list-group-item-danger {
  color: #932222;
  background-color: #fdd7d7;
}

.list-group-item-light {
  color: #959698;
  background-color: #fefeff;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.link-primary {
  color: #387bf5;
}

.link-primary:focus,
.link-primary:hover {
  color: #2d62c4;
}

.link-danger {
  color: #f53838;
}

.link-danger:focus,
.link-danger:hover {
  color: #c42d2d;
}

.link-light {
  color: #f9fafd;
}

.link-light:focus,
.link-light:hover {
  color: #fafbfd;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.border {
  border: 1px solid #bec3d0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #bec3d0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #bec3d0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #bec3d0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-facebook {
  border-color: #3c5a99 !important;
}

.border-twitter {
  border-color: #1da1f2 !important;
}

.border-youtube {
  border-color: red !important;
}

.border-black {
  border-color: #000 !important;
}

.border-100 {
  border-color: #f6f6f6 !important;
}

.border-200 {
  border-color: #f8f8f8 !important;
}

.border-300 {
  border-color: #bec3d0 !important;
}

.border-400 {
  border-color: #7a839e !important;
}

.border-500 {
  border-color: #6f7995 !important;
}

.border-600 {
  border-color: #4d5469 !important;
}

.border-700 {
  border-color: #4d5769 !important;
}

.border-800 {
  border-color: #4d5969 !important;
}

.border-900 {
  border-color: #4f5665 !important;
}

.border-1000 {
  border-color: #0b132a !important;
}

.border-grey {
  border-color: #243e63 !important;
}

.border-1100 {
  border-color: #0b1727 !important;
}

.border-primary {
  border-color: #387bf5 !important;
}

.border-danger {
  border-color: #f53838 !important;
}

.border-light {
  border-color: #f9fafd !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.order-0 {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.8rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.m-9 {
  margin: 10rem !important;
}

.m-10 {
  margin: 12.5rem !important;
}

.m-11 {
  margin: 15rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.8rem !important;
  margin-left: 1.8rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-7 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-8 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-9 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-10 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-11 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.8rem !important;
  margin-bottom: 1.8rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-7 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-9 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-10 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-11 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt--1 {
  margin-top: -0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.8rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 7.5rem !important;
}

.mt-9 {
  margin-top: 10rem !important;
}

.mt-10 {
  margin-top: 12.5rem !important;
}

.mt-11 {
  margin-top: 15rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.8rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 7.5rem !important;
}

.mb-9 {
  margin-bottom: 10rem !important;
}

.mb-10 {
  margin-bottom: 12.5rem !important;
}

.mb-11 {
  margin-bottom: 15rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.8rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4rem !important;
}

.ms-7 {
  margin-left: 5rem !important;
}

.ms-8 {
  margin-left: 7.5rem !important;
}

.ms-9 {
  margin-left: 10rem !important;
}

.ms-10 {
  margin-left: 12.5rem !important;
}

.ms-11 {
  margin-left: 15rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.8rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.p-9 {
  padding: 10rem !important;
}

.p-10 {
  padding: 12.5rem !important;
}

.p-11 {
  padding: 15rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.8rem !important;
  padding-left: 1.8rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-7 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-8 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-9 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-10 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-11 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-8 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-9 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-10 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-11 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.8rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 7.5rem !important;
}

.pt-9 {
  padding-top: 10rem !important;
}

.pt-10 {
  padding-top: 12.5rem !important;
}

.pt-11 {
  padding-top: 15rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.8rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 7.5rem !important;
}

.pb-9 {
  padding-bottom: 10rem !important;
}

.pb-10 {
  padding-bottom: 12.5rem !important;
}

.pb-11 {
  padding-bottom: 15rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.8rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4rem !important;
}

.ps-7 {
  padding-left: 5rem !important;
}

.ps-8 {
  padding-left: 7.5rem !important;
}

.ps-9 {
  padding-left: 10rem !important;
}

.ps-10 {
  padding-left: 12.5rem !important;
}

.ps-11 {
  padding-left: 15rem !important;
}

.fs--2 {
  font-size: 0.69444rem !important;
}

.fs--1 {
  font-size: 0.83333rem !important;
}

.fs-0 {
  font-size: 1rem !important;
}

.fs-1 {
  font-size: 1.2rem !important;
}

.fs-2 {
  font-size: calc(1.269rem + 0.228vw) !important;
}

.fs-3 {
  font-size: calc(1.2978rem + 0.5736vw) !important;
}

.fs-4 {
  font-size: calc(1.33236rem + 0.98832vw) !important;
}

.fs-5 {
  font-size: calc(1.37383rem + 1.48598vw) !important;
}

.fs-6 {
  font-size: calc(1.4236rem + 2.08318vw) !important;
}

.fs-7 {
  font-size: calc(1.48332rem + 2.79982vw) !important;
}

.fs-8 {
  font-size: calc(1.55498rem + 3.65978vw) !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #387bf5 !important;
}

.text-danger {
  color: #f53838 !important;
}

.text-light {
  color: #f9fafd !important;
}

.text-body {
  color: #4d5769 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-facebook {
  background-color: #3c5a99 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

.bg-youtube {
  background-color: red !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-100 {
  background-color: #f6f6f6 !important;
}

.bg-200 {
  background-color: #f8f8f8 !important;
}

.bg-300 {
  background-color: #bec3d0 !important;
}

.bg-400 {
  background-color: #7a839e !important;
}

.bg-500 {
  background-color: #6f7995 !important;
}

.bg-600 {
  background-color: #4d5469 !important;
}

.bg-700 {
  background-color: #4d5769 !important;
}

.bg-800 {
  background-color: #4d5969 !important;
}

.bg-900 {
  background-color: #4f5665 !important;
}

.bg-1000 {
  background-color: #0b132a !important;
}

.bg-1100 {
  background-color: #0b1727 !important;
}

.bg-primary {
  background-color: #387bf5 !important;
}

.bg-danger {
  background-color: #f53838 !important;
}

.bg-light {
  background-color: #f9fafd !important;
}

.bg-body {
  background-color: #fbfbfd !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-top-lg {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bottom-lg {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-start-lg {
  border-bottom-left-radius: 0.625rem !important;
  border-top-left-radius: 0.625rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.text-facebook {
  color: #3c5a99 !important;
}

.text-twitter {
  color: #1da1f2 !important;
}

.text-youtube {
  color: red !important;
}

.text-black {
  color: #000 !important;
}

.text-100 {
  color: #f6f6f6 !important;
}

.text-200 {
  color: #f8f8f8 !important;
}

.text-300 {
  color: #bec3d0 !important;
}

.text-400 {
  color: #7a839e !important;
}

.text-500 {
  color: #6f7995 !important;
}

.text-600 {
  color: #4d5469 !important;
}

.text-700 {
  color: #4d5769 !important;
}

.text-800 {
  color: #4d5969 !important;
}

.text-900 {
  color: #4f5665 !important;
}

.text-1000 {
  color: #0b132a !important;
}

.text-1100 {
  color: #0b1727 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.pl-5 {
  padding-left: 0.25rem !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-10 {
  margin-left: 100px !important;
}

@media (min-width: 576px) {
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .border-sm {
    border: 1px solid #bec3d0 !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-top {
    border-top: 1px solid #bec3d0 !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #bec3d0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-start {
    border-left: 1px solid #bec3d0 !important;
  }
  .border-sm-start-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.8rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 10rem !important;
  }
  .m-sm-10 {
    margin: 12.5rem !important;
  }
  .m-sm-11 {
    margin: 15rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-sm-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-sm-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.8rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4rem !important;
  }
  .mt-sm-7 {
    margin-top: 5rem !important;
  }
  .mt-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 10rem !important;
  }
  .mt-sm-10 {
    margin-top: 12.5rem !important;
  }
  .mt-sm-11 {
    margin-top: 15rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 15rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.8rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4rem !important;
  }
  .ms-sm-7 {
    margin-left: 5rem !important;
  }
  .ms-sm-8 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 10rem !important;
  }
  .ms-sm-10 {
    margin-left: 12.5rem !important;
  }
  .ms-sm-11 {
    margin-left: 15rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.8rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 10rem !important;
  }
  .p-sm-10 {
    padding: 12.5rem !important;
  }
  .p-sm-11 {
    padding: 15rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-sm-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-sm-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.8rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4rem !important;
  }
  .pt-sm-7 {
    padding-top: 5rem !important;
  }
  .pt-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 10rem !important;
  }
  .pt-sm-10 {
    padding-top: 12.5rem !important;
  }
  .pt-sm-11 {
    padding-top: 15rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 15rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.8rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4rem !important;
  }
  .ps-sm-7 {
    padding-left: 5rem !important;
  }
  .ps-sm-8 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 10rem !important;
  }
  .ps-sm-10 {
    padding-left: 12.5rem !important;
  }
  .ps-sm-11 {
    padding-left: 15rem !important;
  }
  .fs-sm--2 {
    font-size: 0.69444rem !important;
  }
  .fs-sm--1 {
    font-size: 0.83333rem !important;
  }
  .fs-sm-0 {
    font-size: 1rem !important;
  }
  .fs-sm-1 {
    font-size: 1.2rem !important;
  }
  .fs-sm-2 {
    font-size: calc(1.269rem + 0.228vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.2978rem + 0.5736vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.33236rem + 0.98832vw) !important;
  }
  .fs-sm-5 {
    font-size: calc(1.37383rem + 1.48598vw) !important;
  }
  .fs-sm-6 {
    font-size: calc(1.4236rem + 2.08318vw) !important;
  }
  .fs-sm-7 {
    font-size: calc(1.48332rem + 2.79982vw) !important;
  }
  .fs-sm-8 {
    font-size: calc(1.55498rem + 3.65978vw) !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-sm-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }
  .rounded-sm-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-sm-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }
  .rounded-sm-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-sm-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-sm-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }
  .rounded-sm-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .border-md {
    border: 1px solid #bec3d0 !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-top {
    border-top: 1px solid #bec3d0 !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #bec3d0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-start {
    border-left: 1px solid #bec3d0 !important;
  }
  .border-md-start-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.8rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .m-md-9 {
    margin: 10rem !important;
  }
  .m-md-10 {
    margin: 12.5rem !important;
  }
  .m-md-11 {
    margin: 15rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-md-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-md-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.8rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4rem !important;
  }
  .mt-md-7 {
    margin-top: 5rem !important;
  }
  .mt-md-8 {
    margin-top: 7.5rem !important;
  }
  .mt-md-9 {
    margin-top: 10rem !important;
  }
  .mt-md-10 {
    margin-top: 12.5rem !important;
  }
  .mt-md-11 {
    margin-top: 15rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4rem !important;
  }
  .mb-md-7 {
    margin-bottom: 5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 10rem !important;
  }
  .mb-md-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 15rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.8rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4rem !important;
  }
  .ms-md-7 {
    margin-left: 5rem !important;
  }
  .ms-md-8 {
    margin-left: 7.5rem !important;
  }
  .ms-md-9 {
    margin-left: 10rem !important;
  }
  .ms-md-10 {
    margin-left: 12.5rem !important;
  }
  .ms-md-11 {
    margin-left: 15rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.8rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .p-md-9 {
    padding: 10rem !important;
  }
  .p-md-10 {
    padding: 12.5rem !important;
  }
  .p-md-11 {
    padding: 15rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-md-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-md-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.8rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4rem !important;
  }
  .pt-md-7 {
    padding-top: 5rem !important;
  }
  .pt-md-8 {
    padding-top: 7.5rem !important;
  }
  .pt-md-9 {
    padding-top: 10rem !important;
  }
  .pt-md-10 {
    padding-top: 12.5rem !important;
  }
  .pt-md-11 {
    padding-top: 15rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4rem !important;
  }
  .pb-md-7 {
    padding-bottom: 5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 10rem !important;
  }
  .pb-md-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 15rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.8rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4rem !important;
  }
  .ps-md-7 {
    padding-left: 5rem !important;
  }
  .ps-md-8 {
    padding-left: 7.5rem !important;
  }
  .ps-md-9 {
    padding-left: 10rem !important;
  }
  .ps-md-10 {
    padding-left: 12.5rem !important;
  }
  .ps-md-11 {
    padding-left: 15rem !important;
  }
  .fs-md--2 {
    font-size: 0.69444rem !important;
  }
  .fs-md--1 {
    font-size: 0.83333rem !important;
  }
  .fs-md-0 {
    font-size: 1rem !important;
  }
  .fs-md-1 {
    font-size: 1.2rem !important;
  }
  .fs-md-2 {
    font-size: calc(1.269rem + 0.228vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.2978rem + 0.5736vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.33236rem + 0.98832vw) !important;
  }
  .fs-md-5 {
    font-size: calc(1.37383rem + 1.48598vw) !important;
  }
  .fs-md-6 {
    font-size: calc(1.4236rem + 2.08318vw) !important;
  }
  .fs-md-7 {
    font-size: calc(1.48332rem + 2.79982vw) !important;
  }
  .fs-md-8 {
    font-size: calc(1.55498rem + 3.65978vw) !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-md-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }
  .rounded-md-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-md-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }
  .rounded-md-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-md-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-md-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }
  .rounded-md-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .border-lg {
    border: 1px solid #bec3d0 !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top {
    border-top: 1px solid #bec3d0 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #bec3d0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-start {
    border-left: 1px solid #bec3d0 !important;
  }
  .border-lg-start-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.8rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 10rem !important;
  }
  .m-lg-10 {
    margin: 12.5rem !important;
  }
  .m-lg-11 {
    margin: 15rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-lg-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-lg-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.8rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4rem !important;
  }
  .mt-lg-7 {
    margin-top: 5rem !important;
  }
  .mt-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 10rem !important;
  }
  .mt-lg-10 {
    margin-top: 12.5rem !important;
  }
  .mt-lg-11 {
    margin-top: 15rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 15rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.8rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4rem !important;
  }
  .ms-lg-7 {
    margin-left: 5rem !important;
  }
  .ms-lg-8 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 10rem !important;
  }
  .ms-lg-10 {
    margin-left: 12.5rem !important;
  }
  .ms-lg-11 {
    margin-left: 15rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.8rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 10rem !important;
  }
  .p-lg-10 {
    padding: 12.5rem !important;
  }
  .p-lg-11 {
    padding: 15rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-lg-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-lg-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.8rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4rem !important;
  }
  .pt-lg-7 {
    padding-top: 5rem !important;
  }
  .pt-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 10rem !important;
  }
  .pt-lg-10 {
    padding-top: 12.5rem !important;
  }
  .pt-lg-11 {
    padding-top: 15rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 15rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.8rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4rem !important;
  }
  .ps-lg-7 {
    padding-left: 5rem !important;
  }
  .ps-lg-8 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 10rem !important;
  }
  .ps-lg-10 {
    padding-left: 12.5rem !important;
  }
  .ps-lg-11 {
    padding-left: 15rem !important;
  }
  .fs-lg--2 {
    font-size: 0.69444rem !important;
  }
  .fs-lg--1 {
    font-size: 0.83333rem !important;
  }
  .fs-lg-0 {
    font-size: 1rem !important;
  }
  .fs-lg-1 {
    font-size: 1.2rem !important;
  }
  .fs-lg-2 {
    font-size: calc(1.269rem + 0.228vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.2978rem + 0.5736vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.33236rem + 0.98832vw) !important;
  }
  .fs-lg-5 {
    font-size: calc(1.37383rem + 1.48598vw) !important;
  }
  .fs-lg-6 {
    font-size: calc(1.4236rem + 2.08318vw) !important;
  }
  .fs-lg-7 {
    font-size: calc(1.48332rem + 2.79982vw) !important;
  }
  .fs-lg-8 {
    font-size: calc(1.55498rem + 3.65978vw) !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-lg-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }
  .rounded-lg-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-lg-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }
  .rounded-lg-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-lg-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-lg-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }
  .rounded-lg-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .border-xl {
    border: 1px solid #bec3d0 !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top {
    border-top: 1px solid #bec3d0 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #bec3d0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-start {
    border-left: 1px solid #bec3d0 !important;
  }
  .border-xl-start-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.8rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 10rem !important;
  }
  .m-xl-10 {
    margin: 12.5rem !important;
  }
  .m-xl-11 {
    margin: 15rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xl-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xl-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xl-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xl-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.8rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4rem !important;
  }
  .mt-xl-7 {
    margin-top: 5rem !important;
  }
  .mt-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 10rem !important;
  }
  .mt-xl-10 {
    margin-top: 12.5rem !important;
  }
  .mt-xl-11 {
    margin-top: 15rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 15rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.8rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4rem !important;
  }
  .ms-xl-7 {
    margin-left: 5rem !important;
  }
  .ms-xl-8 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 10rem !important;
  }
  .ms-xl-10 {
    margin-left: 12.5rem !important;
  }
  .ms-xl-11 {
    margin-left: 15rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.8rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 10rem !important;
  }
  .p-xl-10 {
    padding: 12.5rem !important;
  }
  .p-xl-11 {
    padding: 15rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xl-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xl-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xl-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xl-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.8rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4rem !important;
  }
  .pt-xl-7 {
    padding-top: 5rem !important;
  }
  .pt-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 10rem !important;
  }
  .pt-xl-10 {
    padding-top: 12.5rem !important;
  }
  .pt-xl-11 {
    padding-top: 15rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 15rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.8rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4rem !important;
  }
  .ps-xl-7 {
    padding-left: 5rem !important;
  }
  .ps-xl-8 {
    padding-left: 7.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 10rem !important;
  }
  .ps-xl-10 {
    padding-left: 12.5rem !important;
  }
  .ps-xl-11 {
    padding-left: 15rem !important;
  }
  .fs-xl--2 {
    font-size: 0.69444rem !important;
  }
  .fs-xl--1 {
    font-size: 0.83333rem !important;
  }
  .fs-xl-0 {
    font-size: 1rem !important;
  }
  .fs-xl-1 {
    font-size: 1.2rem !important;
  }
  .fs-xl-2 {
    font-size: calc(1.269rem + 0.228vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.2978rem + 0.5736vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.33236rem + 0.98832vw) !important;
  }
  .fs-xl-5 {
    font-size: calc(1.37383rem + 1.48598vw) !important;
  }
  .fs-xl-6 {
    font-size: calc(1.4236rem + 2.08318vw) !important;
  }
  .fs-xl-7 {
    font-size: calc(1.48332rem + 2.79982vw) !important;
  }
  .fs-xl-8 {
    font-size: calc(1.55498rem + 3.65978vw) !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xl-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }
  .rounded-xl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xl-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }
  .rounded-xl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xl-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-xl-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }
  .rounded-xl-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1540px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .border-xxl {
    border: 1px solid #bec3d0 !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-xxl-top {
    border-top: 1px solid #bec3d0 !important;
  }
  .border-xxl-top-0 {
    border-top: 0 !important;
  }
  .border-xxl-bottom {
    border-bottom: 1px solid #bec3d0 !important;
  }
  .border-xxl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xxl-start {
    border-left: 1px solid #bec3d0 !important;
  }
  .border-xxl-start-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.8rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4rem !important;
  }
  .m-xxl-7 {
    margin: 5rem !important;
  }
  .m-xxl-8 {
    margin: 7.5rem !important;
  }
  .m-xxl-9 {
    margin: 10rem !important;
  }
  .m-xxl-10 {
    margin: 12.5rem !important;
  }
  .m-xxl-11 {
    margin: 15rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.8rem !important;
    margin-left: 1.8rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-7 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-8 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-xxl-10 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.8rem !important;
    margin-bottom: 1.8rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-7 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-8 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-xxl-10 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-xxl-11 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.8rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4rem !important;
  }
  .mt-xxl-7 {
    margin-top: 5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 10rem !important;
  }
  .mt-xxl-10 {
    margin-top: 12.5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 15rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.8rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 12.5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 15rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.8rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4rem !important;
  }
  .ms-xxl-7 {
    margin-left: 5rem !important;
  }
  .ms-xxl-8 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 10rem !important;
  }
  .ms-xxl-10 {
    margin-left: 12.5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 15rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.8rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4rem !important;
  }
  .p-xxl-7 {
    padding: 5rem !important;
  }
  .p-xxl-8 {
    padding: 7.5rem !important;
  }
  .p-xxl-9 {
    padding: 10rem !important;
  }
  .p-xxl-10 {
    padding: 12.5rem !important;
  }
  .p-xxl-11 {
    padding: 15rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.8rem !important;
    padding-left: 1.8rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-7 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-8 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-xxl-10 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-xxl-11 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.8rem !important;
    padding-bottom: 1.8rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-8 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-xxl-10 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-xxl-11 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.8rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4rem !important;
  }
  .pt-xxl-7 {
    padding-top: 5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 7.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 10rem !important;
  }
  .pt-xxl-10 {
    padding-top: 12.5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 15rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.8rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 12.5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 15rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.8rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4rem !important;
  }
  .ps-xxl-7 {
    padding-left: 5rem !important;
  }
  .ps-xxl-8 {
    padding-left: 7.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 10rem !important;
  }
  .ps-xxl-10 {
    padding-left: 12.5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 15rem !important;
  }
  .fs-xxl--2 {
    font-size: 0.69444rem !important;
  }
  .fs-xxl--1 {
    font-size: 0.83333rem !important;
  }
  .fs-xxl-0 {
    font-size: 1rem !important;
  }
  .fs-xxl-1 {
    font-size: 1.2rem !important;
  }
  .fs-xxl-2 {
    font-size: calc(1.269rem + 0.228vw) !important;
  }
  .fs-xxl-3 {
    font-size: calc(1.2978rem + 0.5736vw) !important;
  }
  .fs-xxl-4 {
    font-size: calc(1.33236rem + 0.98832vw) !important;
  }
  .fs-xxl-5 {
    font-size: calc(1.37383rem + 1.48598vw) !important;
  }
  .fs-xxl-6 {
    font-size: calc(1.4236rem + 2.08318vw) !important;
  }
  .fs-xxl-7 {
    font-size: calc(1.48332rem + 2.79982vw) !important;
  }
  .fs-xxl-8 {
    font-size: calc(1.55498rem + 3.65978vw) !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .rounded-xxl-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-xxl-top-lg {
    border-top-left-radius: 0.625rem !important;
    border-top-right-radius: 0.625rem !important;
  }
  .rounded-xxl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xxl-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-xxl-bottom-lg {
    border-bottom-right-radius: 0.625rem !important;
    border-bottom-left-radius: 0.625rem !important;
  }
  .rounded-xxl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xxl-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
  }
  .rounded-xxl-start-lg {
    border-bottom-left-radius: 0.625rem !important;
    border-top-left-radius: 0.625rem !important;
  }
  .rounded-xxl-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
}

@media (min-width: 1200px) {
  .fs-2 {
    font-size: 1.44rem !important;
  }
  .fs-3 {
    font-size: 1.728rem !important;
  }
  .fs-4 {
    font-size: 2.0736rem !important;
  }
  .fs-5 {
    font-size: 2.48832rem !important;
  }
  .fs-6 {
    font-size: 2.98598rem !important;
  }
  .fs-7 {
    font-size: 3.58318rem !important;
  }
  .fs-8 {
    font-size: 4.29982rem !important;
  }
  .fs-sm-2 {
    font-size: 1.44rem !important;
  }
  .fs-sm-3 {
    font-size: 1.728rem !important;
  }
  .fs-sm-4 {
    font-size: 2.0736rem !important;
  }
  .fs-sm-5 {
    font-size: 2.48832rem !important;
  }
  .fs-sm-6 {
    font-size: 2.98598rem !important;
  }
  .fs-sm-7 {
    font-size: 3.58318rem !important;
  }
  .fs-sm-8 {
    font-size: 4.29982rem !important;
  }
  .fs-md-2 {
    font-size: 1.44rem !important;
  }
  .fs-md-3 {
    font-size: 1.728rem !important;
  }
  .fs-md-4 {
    font-size: 2.0736rem !important;
  }
  .fs-md-5 {
    font-size: 2.48832rem !important;
  }
  .fs-md-6 {
    font-size: 2.98598rem !important;
  }
  .fs-md-7 {
    font-size: 3.58318rem !important;
  }
  .fs-md-8 {
    font-size: 4.29982rem !important;
  }
  .fs-lg-2 {
    font-size: 1.44rem !important;
  }
  .fs-lg-3 {
    font-size: 1.728rem !important;
  }
  .fs-lg-4 {
    font-size: 2.0736rem !important;
  }
  .fs-lg-5 {
    font-size: 2.48832rem !important;
  }
  .fs-lg-6 {
    font-size: 2.98598rem !important;
  }
  .fs-lg-7 {
    font-size: 3.58318rem !important;
  }
  .fs-lg-8 {
    font-size: 4.29982rem !important;
  }
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #7a839e !important;
}

::-moz-placeholder {
  opacity: 1;
  color: #7a839e !important;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #7a839e !important;
}

::-ms-input-placeholder {
  opacity: 1;
  color: #7a839e !important;
}

::placeholder {
  opacity: 1;
  color: #7a839e !important;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 6.3125rem;
}

body {
  -webkit-font-smoothing: antialiased;
  position: relative;
}

section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (min-width: 992px) {
  section {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
}

label {
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
}

.fs-error {
  font-size: 7rem;
}

@media (min-width: 576px) {
  .fs-error {
    font-size: 10rem;
  }
}

.text-justify {
  text-align: justify !important;
}

@media (max-width: 991px) {
  .bb-1 {
    border-bottom: 1px solid rgb(230, 230, 230) !important;
  }
}

.border-top-2 {
  border-top-width: 0.125rem !important;
}

.border-bottom-2 {
  border-bottom-width: 0.125rem !important;
}

.border-start-2 {
  border-left-width: 0.125rem !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  position: relative;
}

.flex-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.link-black {
  color: #000 !important;
}

.link-black:focus,
.link-black:hover {
  color: #000 !important;
}

.link-100 {
  color: #f6f6f6 !important;
}

.link-100:focus,
.link-100:hover {
  color: #d0d0d0 !important;
}

.link-200 {
  color: #f8f8f8 !important;
}

.link-200:focus,
.link-200:hover {
  color: #d2d2d2 !important;
}

.link-300 {
  color: #bec3d0 !important;
}

.link-300:focus,
.link-300:hover {
  color: #929ab0 !important;
}

.link-400 {
  color: #7a839e !important;
}

.link-400:focus,
.link-400:hover {
  color: #565e76 !important;
}

.link-500 {
  color: #6f7995 !important;
}

.link-500:focus,
.link-500:hover {
  color: #4e556a !important;
}

.link-600 {
  color: #4d5469 !important;
}

.link-600:focus,
.link-600:hover {
  color: #2d313d !important;
}

.link-700 {
  color: #4d5769 !important;
}

.link-700:focus,
.link-700:hover {
  color: #2d323d !important;
}

.link-800 {
  color: #4d5969 !important;
}

.link-800:focus,
.link-800:hover {
  color: #2d343d !important;
}

.link-900 {
  color: #4f5665 !important;
}

.link-900:focus,
.link-900:hover {
  color: #2d313a !important;
}

.link-1000 {
  color: #0b132a !important;
}

.link-1000:focus,
.link-1000:hover {
  color: #000 !important;
}

.link-1100 {
  color: #0b1727 !important;
}

.link-1100:focus,
.link-1100:hover {
  color: #000 !important;
}

.hover-text-decoration-none:focus,
.hover-text-decoration-none:hover {
  text-decoration: none;
}

[data-dismiss="dropdown"] *,
[data-offset-top] *,
[data-toggle="collapse"] *,
[data-toggle="popover"] *,
[data-toggle="tooltip"] * {
  pointer-events: none;
}

.hover-top {
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.hover-top:focus,
.hover-top:hover {
  -webkit-transform: translateY(-0.125rem) translateZ(0);
  transform: translateY(-0.125rem) translateZ(0);
  -webkit-box-shadow: 0.5rem 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
  box-shadow: 0.5rem 0.5rem 1.5rem rgba(22, 28, 45, 0.1) !important;
}
